import React from 'react';
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider, createTheme } from 'baseui';
import { Client as Styletron } from "styletron-engine-atomic";
import Layout from './src/components/Layout';

const primitives = {
    primaryFontFamily: 'Work Sans',
  };
  
const theme = createTheme(primitives);

const engine = new Styletron({
    hydrate: document.getElementsByClassName('_styletron_hydrate_'),
});

export const wrapRootElement = ({ element }) => {

    if (!engine) return null;

    return (
        <StyletronProvider value={engine}>
          <BaseProvider theme={theme}>
              {element}
          </BaseProvider>
        </StyletronProvider>
    )
}

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
  }