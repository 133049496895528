import React, { forwardRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { Drawer, SIZE } from 'baseui/drawer';
import { StatefulMenu } from 'baseui/menu';
import { Menu } from 'baseui/icon';
import { ListItemLabel, MenuAdapter } from 'baseui/list';
import { Button, KIND, SHAPE } from 'baseui/button';


const NavDrawer = props => {
    const { menuLinks } = props;
    const [css] = useStyletron();
    const [isOpen, setIsOpen] = useState(false);

    const items = menuLinks.map(link => ({
        label: link.name,
        href: link.link
    }))

    const close = () => {
        setIsOpen(false);
    }

    return (
        <React.Fragment>
            <Button
            className={css({
                ":enabled:hover": {background: "#1F70E9"},
            })}
            kind={KIND.minimal}
            shape={SHAPE.circle}
            onClick={() => setIsOpen(true)}
            >
                <Menu size={40} color="#FFFFFF"/>
            </Button>
            <Drawer
                onClose={() => close()}
                isOpen={isOpen}
                size={SIZE.full}
            >
                <StatefulMenu
                    items={items}
                    overrides={{
                        List: {
                            style: {
                                height: '100%'
                            }
                        },
                        Option: {
                            props: {
                                overrides: {
                                    ListItem: {
                                        component: forwardRef((props, ref) => (
                                            <MenuAdapter
                                                {...props}
                                                ref={ref}
                                            >
                                                <ListItemLabel>
                                                    {props.item.label}
                                                </ListItemLabel>
                                            </MenuAdapter>
                                        ))
                                    }
                                }
                            }
                        }
                    }}
                />
            </Drawer>
        </React.Fragment>
    )
}

export default NavDrawer;