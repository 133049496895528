import React from 'react';
import { StaticQuery, graphql, withPrefix, Link } from "gatsby";
import Logo from "../../static/images/surfboardwhite.svg";
import Header from "../Header";
import { Helmet } from 'react-helmet';
import SectionContainer from '../SectionContainer'
import CookieConsent from "react-cookie-consent";

import './_layout.scss';

const Layout = ({ children }) => {
    
    const heroLogo = <Logo className="surfboard-logo"/>
  
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                menuLinks {
                  name
                  link
                }
              }
            }
          }
        `}
        render={data => (
            <div className="surfboard-body">
              <Helmet
                title={'Surfboard - Share your Arduino code to your community'}
                meta={[
                  { name: 'description', content: 'Build for the world.' },
                  { name: 'keywords', content: 'Arduino, sketch, flash, share, hardware, development' },
                ]}
              >
                <script type="application/ld+json">
                  {`
                      {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "url": "https://www.sfbd.is",
                        "name": "Surfboard Technologies",
                        "contactPoint": {
                          "@type": "ContactPoint",
                          "email": "birudeghi@gmail.com",
                          "contactType": "Technical Support" 
                        }
                      }
                  `}
                </script>
              </Helmet>
              <Header menuLinks={data.site.siteMetadata.menuLinks} siteTitle={heroLogo} />
              {children}
              <div className="footer">
                <SectionContainer className="footer-how section-footer" fullwidth={true} backgroundColor="dark">
                  <div className="section-how-action">
                        <Link
                          className="footer--surf-title-link"
                          to="/"
                        >
                          {heroLogo}
                        </Link>
                      <div className="footer-links">
                              <Link className="link-1" to="/terms">Terms of Service</Link>
                              <Link className="link-2" to="/privacy">Privacy Policy</Link>
                      </div>
                      <div className="social-media">
                          <a href="https://discord.gg/mwNrE2WCAu" className="icon-discord">
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-discord" viewBox="0 0 16 16">
                                  <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
                              </svg>
                          </a>
                      </div>
                  </div>
                </SectionContainer>
              </div>
              
              <CookieConsent
                enableDeclineButton
                flipButtons
                location="bottom"
                buttonText="Yum, cookies!"
                declineButtonStyle={{ background: 'transparent', border: 'none', color: '#FFFFFF', fontWeight: 500, }}
                style={{ background: '#111111' }}
                declineButtonText="Decline"
                buttonStyle={{
                  backgroundColor: 'transparent',
                  color: '#FFFFFF',
                  fontWeight: 500,
                }}
                cookieName="google-analytics-cookie"
                onDecline={() => {
                  document.cookie = "ga-disable-G-W1RN9ZEWMS=true; expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/";
                  window.disableStr = 1;
                }}  
              >
                We use cookies to gather basic analytics on how visitors use our website. <Link className="privacy-link" to="/privacy">Learn more</Link>
              </CookieConsent>
            </div>  
              
        )}
      />
    )
}

export default Layout;